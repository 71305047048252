import toolsIcon from '@/assets/nav-icon/一周心理训练1@2x.png';
import toolsActiveIcon from '@/assets/nav-icon/一周心理训练2@2x.png';
import recordIcon from '@/assets/nav-icon/布置记录1@2x.png';
import recordActiveIcon from '@/assets/nav-icon/布置记录2@2x.png';
import psyIntroIcon from '@/assets/nav-icon/德育概览1@2x.png';
import psyIntroActiveIcon from '@/assets/nav-icon/德育概览2@2x.png';
import resourceIcon from '@/assets/nav-icon/心理测评1@2x.png';
import resourceActiveIcon from '@/assets/nav-icon/心理测评2@2x.png';
// import collectiveTestIcon from '@/assets/nav-icon/布置记录1@2x.png';
// import collectiveTestActiveIcon from '@/assets/nav-icon/布置记录2@2x.png';
import warningIcon from '@/assets/nav-icon/心理科普1@2x.png';
import warningActiveIcon from '@/assets/nav-icon/心理科普2@2x.png';

export default [
  {
    path: '/psychologyCenter',
    name: 'psychologyCenter',
    redirect: '/psychologyCenter/home',
    component: () => import('@/views/psychology-center/entry.vue'),
    meta: {
      role: 'moralismCenter',
      roleId: '400',
      menuName: '心理健康',
    },
    children: [
      {
        path: 'home',
        name: 'psychologyCenterHome',
        redirect: '/psychologyCenter/home/index',
        component: () => import('@/views/psychology-center/home/entry.vue'),
        meta: {
          role: 'moralismCenter.index',
          roleId: '435',
          menuName: '心理概览',
          icon: psyIntroIcon,
          activeIcon: psyIntroActiveIcon,
        },
        children: [
          {
            path: 'index',
            name: 'psychologyCenterHomeIndex',
            component: () =>
              import('@/views/psychology-center/home/index/entry.vue'),
            meta: {
              role: 'moralismCenter.index.view',
              roleId: '436',
              hideInMenu: true,
            },
          },
          {
            path: 'information-list',
            name: 'informationList',
            component: () =>
              import(
                '@/views/psychology-center/home/information-list/index.vue'
              ),
            meta: {
              hideInMenu: true,
            },
          },
          {
            path: 'information-detail',
            name: 'informationDetail',
            component: () =>
              import(
                '@/views/psychology-center/home/information-detail/index.vue'
              ),
            meta: {
              hideInMenu: true,
            },
          },
        ],
      },
      {
        path: '',
        name: 'psychologyCenterTesting',
        redirect: '/psychologyCenter/testing/list',
        component: () => import('@/views/psychology-center/testing/entry.vue'),
        meta: {
          role: 'moralismCenter.tools',
          roleId: '11004',
          open: true,
          hasSubmenu: true,
          menuName: '心理健康测评',
          icon: toolsIcon,
          activeIcon: toolsActiveIcon,
        },
        children: [
          {
            path: 'testing',
            name: 'testing',
            redirect: '/psychologyCenter/testing/list',
            component: () =>
              import('@/views/psychology-center/testing/entry.vue'),
            meta: {
              role: 'moralismCenter.psychology',
              roleId: '408',
              menuName: '心理测评',
            },
            children: [
              {
                path: 'list',
                name: 'testingList',
                component: () =>
                  import('@/views/psychology-center/testing/index/entry.vue'),
                meta: {
                  role: 'moralismCenter.psychology.list',
                  roleId: '409',
                  hideInMenu: true,
                },
              },
              {
                path: 'detail',
                name: 'testingDetail',
                component: () =>
                  import(
                    '@/views/psychology-center/testing/testing_detail/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.psychology.detail',
                  roleId: '410',
                  hideInMenu: true,
                },
              },
              {
                path: 'arrange',
                name: 'testingHomeworkArrange',
                component: () =>
                  import(
                    '@/views/psychology-center/components/testing_homework_arrange/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.psychology.assign',
                  roleId: '411',
                  hideInMenu: true,
                },
              },
              {
                path: 'arrangeFinish',
                name: 'testingHomeworkArrangeFinish',
                component: () =>
                  import(
                    '@/views/psychology-center/components/testing_homework_arrange_finish/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.psychology.assignSuccess',
                  roleId: '412',
                  hideInMenu: true,
                },
              },
              {
                path: 'arrangeBatch',
                name: 'testingArrangeBatch',
                component: () =>
                  import(
                    '@/views/psychology-center/components/testing_arrange_batch/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.psychology.batchAssign',
                  roleId: '413',
                  hideInMenu: true,
                },
              },
              {
                path: 'arrangeBatchOver',
                name: 'arrangeBatchOver',
                component: () =>
                  import(
                    '@/views/psychology-center/components/testing_arrange_batch_over/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.psychology.batchAssignSuccess',
                  roleId: '414',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: 'psychologicalWarning',
            name: 'psychologicalWarning',
            redirect: '/psychologyCenter/psychologicalWarning/list',
            component: () =>
              import(
                '@/views/psychology-center/psychological-warning/entry.vue'
              ),
            meta: {
              role: 'moralismCenter.crisisWarning',
              roleId: '440',
              menuName: '心理预警',
            },
            children: [
              {
                path: 'list',
                name: 'psychologicalWarningList',
                component: () =>
                  import(
                    '@/views/psychology-center/psychological-warning/list/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.crisisWarning.list',
                  roleId: '441',
                  hideInMenu: true,
                },
              },
              {
                path: 'detail',
                name: 'psychologicalWarningDetail',
                component: () =>
                  import(
                    '@/views/psychology-center/psychological-warning/detail/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.crisisWarning.detail',
                  roleId: '442',
                  hideInMenu: true,
                },
              },
              {
                path: 'arrange',
                name: 'psychologicalWarningArrange',
                component: () =>
                  import(
                    '@/views/psychology-center/psychological-warning/arrange/entry.vue'
                  ),
                meta: {
                  role: '',
                  roleId: '',
                  hideInMenu: true,
                },
              },
            ],
          },

          {
            path: 'testRecord',
            name: 'testRecord',
            redirect: '/psychologyCenter/testRecord/list',
            component: () =>
              import('@/views/psychology-center/test-record/index.vue'),
            meta: {
              role: 'moralismCenter.psyTest.testAssignInfo',
              roleId: '437',
              menuName: '布置记录',
            },
            children: [
              {
                path: 'list',
                name: 'testRecordList',
                component: () =>
                  import(
                    '@/views/psychology-center/record/testing_record_list/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.testTraining.list',
                  roleId: '420',
                  hideInMenu: true,
                },
              },
              {
                path: 'testingAgainArrange',
                name: 'testingAgainArrange',
                component: () =>
                  import(
                    '@/views/psychology-center/components/testing_homework_arrange/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.testTraining.testAssign',
                  roleId: '433',
                  hideInMenu: true,
                },
              },
              {
                path: 'testingAgainArrangeFinish',
                name: 'testingAgainArrangeFinish',
                component: () =>
                  import(
                    '@/views/psychology-center/components/testing_homework_arrange_finish/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.testTraining.testAssignComplete',
                  roleId: '434',
                  hideInMenu: true,
                },
              },
              {
                path: 'testRecordDetail',
                name: 'testRecordDetail',
                component: () =>
                  import(
                    '@/views/psychology-center/record/testing_homework_record_detail/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.testTraining.test.detail',
                  roleId: '421',
                  hideInMenu: true,
                },
              },
            ],
          },
        ],
      },
      /* 无感知告警 */
      {
        path: '',
        name: 'psychologyCenterNoPerceptualWarning',
        redirect: '/psychologyCenter/noPerceptualWarning/list',
        component: () =>
          import('@/views/psychology-center/no-perceptual-warning/entry.vue'),
        meta: {
          role: 'moralismCenter.noperceptualalarm',
          roleId: '11041',
          open: true,
          hasSubmenu: true,
          menuName: '无感知告警',
          icon: warningIcon,
          activeIcon: warningActiveIcon,
        },
        children: [
          {
            path: 'noPerceptualWarning',
            name: 'noPerceptualWarning',
            redirect: '/psychologyCenter/noPerceptualWarning/list',
            component: () =>
              import(
                '@/views/psychology-center/no-perceptual-warning/entry.vue'
              ),
            meta: {
              role: 'moralismCenter.noperceptualalarm',
              roleId: '11041',
              menuName: '告警列表',
            },
            children: [
              {
                path: 'list',
                name: 'noPerceptualWarningList',
                component: () =>
                  import(
                    '@/views/psychology-center/no-perceptual-warning/list/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.noperceptualalarm.list',
                  roleId: '11042',
                  hideInMenu: true,
                },
              },
              {
                path: 'desc',
                name: 'noPerceptualWarningDesc',
                component: () =>
                  import(
                    '@/views/psychology-center/no-perceptual-warning/desc/entry.vue'
                  ),
                meta: {
                  hideInMenu: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: '',
        name: 'psychologyCenterTraining',
        redirect: '/psychologyCenter/training/list',
        component: () => import('@/views/psychology-center/training/entry.vue'),
        meta: {
          role: 'moralismCenter.resource',
          roleId: '11003',
          open: true,
          hasSubmenu: true,
          menuName: '心理健康资源',
          icon: resourceIcon,
          activeIcon: resourceActiveIcon,
        },
        children: [
          {
            path: 'psyMeeting',
            name: 'psyMeeting',
            redirect: '/psychologyCenter/psyMeeting/list',
            component: () =>
              import('@/views/psychology-center/psyMeeting/entry.vue'),
            meta: {
              role: 'moralismCenter.psyMeeting',
              roleId: '11289',
              menuName: '心海奇遇记',
            },
            children: [
              {
                path: 'list',
                name: 'psyMeetingList',
                component: () =>
                  import(
                    '@/views/psychology-center/psyMeeting/views/list/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.psyMeeting.page',
                  roleId: '11290',
                  hideInMenu: true,
                },
              },
              {
                path: 'detail',
                name: 'psyMeetingDetail',
                component: () =>
                  import(
                    '@/views/psychology-center/psyMeeting/views/detail/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.psyMeeting.detail',
                  roleId: '11291',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: 'training',
            name: 'training',
            redirect: '/psychologyCenter/training/list',
            component: () =>
              import('@/views/psychology-center/training/entry.vue'),
            meta: {
              role: 'moralismCenter.dailyPsychologyTraining',
              roleId: '415',
              menuName: '视频资源',
            },
            children: [
              {
                path: 'list',
                name: 'trainingList',
                component: () =>
                  import(
                    '@/views/psychology-center/training/training/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.dailyPsychologyTraining.list',
                  roleId: '416',
                  hideInMenu: true,
                },
              },
              {
                path: 'detail',
                name: 'trainingDetail',
                component: () =>
                  import(
                    '@/views/psychology-center/training/testing_train_course_detail/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.dailyPsychologyTraining.detail',
                  roleId: '417',
                  hideInMenu: true,
                },
              },
              {
                path: 'arrange',
                name: 'trainingArrange',
                component: () =>
                  import(
                    '@/views/psychology-center/components/testing_train_arrange/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.dailyPsychologyTraining.assign',
                  roleId: '418',
                  hideInMenu: true,
                },
              },
              {
                path: 'arrangeFinish',
                name: 'trainingArrangeFinish',
                component: () =>
                  import(
                    '@/views/psychology-center/components/testing_train_arrange_finish/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.dailyPsychologyTraining.assignComplete',
                  roleId: '426',
                  hideInMenu: true,
                },
              },
              {
                path: 'arrangeBatch',
                name: 'trainingArrangeBatch',
                component: () =>
                  import(
                    '@/views/psychology-center/components/testing_arrange_batch/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.dailyPsychologyTraining.batchAssign',
                  roleId: '427',
                  hideInMenu: true,
                },
              },
              {
                path: 'arrangeBatchOver',
                name: 'trainingArrangeBatchOver',
                component: () =>
                  import(
                    '@/views/psychology-center/components/testing_arrange_batch_over/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.dailyPsychologyTraining.batchAssignComplete',
                  roleId: '428',
                  hideInMenu: true,
                },
              },
              {
                path: 'trainingLessonPlay',
                name: 'trainingLessonPlay',
                component: () =>
                  import(
                    '@/views/psychology-center/training/testing_train_lesson_play/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.dailyPsychologyTraining.classBegin',
                  roleId: '429',
                  hideInMenu: true,
                },
              },
              {
                path: 'trainingLessonExpand',
                name: 'trainingLessonExpand',
                component: () =>
                  import(
                    '@/views/psychology-center/training/testing_train_lesson_expand/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.dailyPsychologyTraining.extraTraining',
                  roleId: '430',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: 'fm',
            name: 'fm',
            redirect: '/psychologyCenter/fm/index',
            component: () => import('@/views/psychology-center/fm/entry.vue'),
            meta: {
              role: 'moralismCenter.youthFM',
              roleId: '401',
              menuName: '心晴FM',
            },
            children: [
              {
                path: 'index',
                name: 'fmIndex',
                component: () =>
                  import('@/views/psychology-center/fm/entry.vue'),
                meta: {
                  role: 'moralismCenter.youthFM.list',
                  roleId: '402',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: 'classmeet',
            name: 'classmeet',
            redirect: '/psychologyCenter/classmeet/list',
            component: () =>
              import('@/views/psychology-center/classmeet/entry.vue'),
            meta: {
              role: 'moralismCenter.coursewareResources',
              roleId: '405',
              menuName: '课件资源',
            },
            children: [
              {
                path: 'list',
                name: 'classmeetList',
                component: () =>
                  import(
                    '@/views/psychology-center/classmeet/classmeet-list/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.coursewareResources',
                  roleId: '405',
                  hideInMenu: true,
                },
              },
              {
                path: 'detail',
                name: 'classmeetDetail',
                component: () =>
                  import(
                    '@/views/psychology-center/classmeet/classmeet-detail/entry.vue'
                  ),
                meta: {
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: 'poster',
            name: 'poster',
            redirect: '/psychologyCenter/poster/index',
            component: () =>
              import('@/views/psychology-center/poster/entry.vue'),
            meta: {
              role: 'moralismCenter.schoolPoster',
              roleId: '403',
              menuName: '校园宣传海报',
            },
            children: [
              {
                path: 'index',
                name: 'posterIndex',
                component: () =>
                  import('@/views/psychology-center/poster/entry.vue'),
                meta: {
                  role: 'moralismCenter.schoolPoster.list',
                  roleId: '404',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: 'trainRecord',
            name: 'trainRecord',
            redirect: '/psychologyCenter/trainRecord/list',
            component: () =>
              import('@/views/psychology-center/train-record/index.vue'),
            meta: {
              role: 'moralismCenter.psyTraining.trainingInfo',
              roleId: '438',
              menuName: '布置记录',
            },
            children: [
              {
                path: 'list',
                name: 'trainRecordList',
                component: () =>
                  import(
                    '@/views/psychology-center/record/testing_record_list/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.psyTraining.trainingInfo.list',
                  roleId: '439',
                  hideInMenu: true,
                },
              },
              {
                path: 'trainingAgainArrange',
                name: 'trainingAgainArrange',
                component: () =>
                  import(
                    '@/views/psychology-center/components/testing_train_arrange/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.testTraining.trainingAssign',
                  roleId: '431',
                  hideInMenu: true,
                },
              },
              {
                path: 'trainingAgainArrangeFinish',
                name: 'trainingAgainArrangeFinish',
                component: () =>
                  import(
                    '@/views/psychology-center/components/testing_train_arrange_finish/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.testTraining.trainingAssignComplete',
                  roleId: '432',
                  hideInMenu: true,
                },
              },
              {
                path: 'trainRecordDetail',
                name: 'trainRecordDetail',
                component: () =>
                  import(
                    '@/views/psychology-center/record/testing_train_record_detail/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.testTraining.training.detail',
                  roleId: '422',
                  hideInMenu: true,
                },
              },
            ],
          },
        ],
      },
      /** 心理健康咨询 */
      {
        path: '',
        name: 'PsychologyCenterConsult',
        redirect: '/psychologyCenter/consultRegister/consultRegisterForm',
        component: () => import('@/views/psychology-center/consult/entry.vue'),
        meta: {
          role: 'moralismCenter.mentalHealthCounselling',
          roleId: '11177',
          open: true,
          hasSubmenu: true,
          menuName: '心理健康咨询',
          icon: resourceIcon,
          activeIcon: resourceActiveIcon,
        },
        children: [
          {
            path: 'consultRegister',
            name: 'consultRegister',
            redirect: '/psychologyCenter/consultRegister/consultRegisterForm',
            component: () =>
              import('@/views/psychology-center/consult/register/entry.vue'),
            meta: {
              role: 'moralismCenter.mentalHealthCounselling.register',
              roleId: '11178',
              menuName: '咨询登记',
            },
            children: [
              {
                path: 'consultRegisterForm',
                name: 'consultRegisterForm',
                component: () =>
                  import(
                    '@/views/psychology-center/consult/register/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.mentalHealthCounselling.register',
                  roleId: '11178',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: 'consultRecord',
            name: 'consultRecord',
            component: () =>
              import('@/views/psychology-center/consult/record/list/entry.vue'),
            redirect: '/psychologyCenter/consultRecord/consultRecordList',
            meta: {
              role: 'moralismCenter.mentalHealthCounselling.records',
              roleId: '11179',
              menuName: '咨询记录',
            },
            children: [
              {
                path: 'consultRecordList',
                name: 'consultRecordList',
                component: () =>
                  import(
                    '@/views/psychology-center/consult/record/list/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.mentalHealthCounselling.records',
                  roleId: '11179',
                  hideInMenu: true,
                },
              },
            ],
          },
        ],
      },
      /* 心理档案 */
      {
        path: '',
        name: 'psychologyCenterRecord',
        redirect: '/psychologyCenter/psyRecord/list',
        component: () =>
          import('@/views/psychology-center/psy-record/entry.vue'),
        meta: {
          role: 'moralismCenter.mentalfile',
          roleId: '11095',
          open: true,
          hasSubmenu: true,
          menuName: '心理健康管理',
          icon: recordIcon,
          activeIcon: recordActiveIcon,
        },
        children: [
          {
            path: 'psyRecord',
            name: 'psyRecord',
            redirect: '/psychologyCenter/psyRecord/list',
            component: () =>
              import('@/views/psychology-center/psy-record/entry.vue'),
            meta: {
              role: 'moralismCenter.mentalfile',
              roleId: '11095',
              menuName: '成长档案',
            },
            children: [
              {
                path: 'list',
                name: 'psyRecordList',
                component: () =>
                  import('@/views/psychology-center/psy-record/list/entry.vue'),
                meta: {
                  role: 'moralismCenter.mentalfile.studentList',
                  roleId: '11096',
                  hideInMenu: true,
                },
              },
              {
                path: 'detail',
                name: 'psyRecordDetail',
                component: () =>
                  import(
                    '@/views/psychology-center/psy-record/detail/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.mentalfile.studentDetail',
                  roleId: '11097',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: 'psyDataManagement',
            name: 'PsyDataManagement',
            redirect: '/psychologyCenter/psyDataManagement/list',
            component: () =>
              import('@/views/psychology-center/data-management/entry.vue'),
            meta: {
              role: 'moralismCenter.featureWork.page',
              roleId: '11292',
              menuName: '数据管理',
            },
            children: [
              {
                path: 'list',
                name: 'PsyDataManagementList',
                component: () =>
                  import(
                    '@/views/psychology-center/data-management/list/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.featureWork.page',
                  roleId: '11292',
                  hideInMenu: true,
                },
              },
              {
                path: 'detail',
                name: 'PsyDataManagementDetail',
                component: () =>
                  import(
                    '@/views/psychology-center/data-management/detail/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.featureWork.detail',
                  roleId: '11293',
                  hideInMenu: true,
                },
              },
            ],
          },
        ],
      },

      /* 身体健康管理 */
      {
        path: '',
        name: 'psyHealthManagement',
        redirect: '/psychologyCenter/psyHealthManagementList/list',
        component: () =>
          import('@/views/psychology-center/health-management/entry.vue'),
        meta: {
          role: 'moralismCenter.bodyManager',
          roleId: '446',
          open: true,
          hasSubmenu: true,
          menuName: '身体健康管理',
          icon: recordIcon,
          activeIcon: recordActiveIcon,
        },
        children: [
          {
            path: 'psyHealthManagementList',
            name: 'psyHealthManagementList',
            redirect: '/psychologyCenter/psyHealthManagementList/list',
            component: () =>
              import('@/views/psychology-center/health-management/entry.vue'),
            meta: {
              role: 'moralismCenter.bodyManager',
              roleId: '446',
              menuName: '健康档案',
            },
            children: [
              {
                path: 'list',
                name: 'psyHealthManagementList',
                component: () =>
                  import(
                    '@/views/psychology-center/health-management/list/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.bodyManager',
                  roleId: '446',
                  hideInMenu: true,
                },
              },
              {
                path: 'detail',
                name: 'psyHealthManagementDetail',
                component: () =>
                  import(
                    '@/views/psychology-center/health-management/detail/entry.vue'
                  ),
                meta: {
                  role: 'moralismCenter.bodyManager.detail',
                  roleId: '11310',
                  hideInMenu: true,
                },
              },
            ],
          },
        ],
      },

      {
        path: 'collectiveReport',
        name: 'collectiveReport',
        redirect: '/psychologyCenter/collectiveReport/list',
        component: () =>
          import('@/views/psychology-center/collective-report/entry.vue'),
        meta: {
          role: 'moralismCenter.collectiveTest',
          roleId: '423',
          menuName: '集体测评报告',
          icon: recordIcon,
          activeIcon: recordActiveIcon,
        },
        children: [
          {
            path: 'list',
            name: 'collectiveReportList',
            component: () =>
              import(
                '@/views/psychology-center/collective-report/collective-evaluation-report-list/entry.vue'
              ),
            meta: {
              role: 'moralismCenter.collectiveTest.list',
              roleId: '424',
              hideInMenu: true,
            },
          },
          {
            path: 'detail',
            name: 'collectiveReportDetail',
            component: () =>
              import(
                '@/views/psychology-center/collective-report/collective-evaluation-report-detail/entry.vue'
              ),
            meta: {
              role: 'moralismCenter.collectiveTest.detail',
              roleId: '425',
              hideInMenu: true,
            },
          },
        ],
      },
      // 心晴漂流瓶
      {
        path: 'driftBottle',
        name: 'driftBottle',
        redirect: '/psychologyCenter/driftBottle/list',
        component: () =>
          import('@/views/psychology-center/drift-bottle/entry.vue'),
        meta: {
          role: 'moralismCenter.bottle',
          roleId: '11308',
          menuName: '心晴漂流瓶',
          icon: psyIntroIcon,
          activeIcon: psyIntroActiveIcon,
        },
        children: [
          {
            path: 'list',
            name: 'driftBottleList',
            component: () =>
              import('@/views/psychology-center/drift-bottle/list/entry.vue'),
            meta: {
              role: 'moralismCenter.bottle.list',
              roleId: '11309',
              hideInMenu: true,
            },
          },
          {
            path: 'blacklist',
            name: 'driftBottleBlacklist',
            component: () =>
              import(
                '@/views/psychology-center/drift-bottle/blacklist/entry.vue'
              ),
            meta: {
              role: 'moralismCenter.bottle.list',
              roleId: '11309',
              hideInMenu: true,
            },
          },
        ],
      },
    ],
  },
];
